import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query FooterQuery {
    craft {
      en: globalSet(handle: "footer") {
        ... on Craft_footer_GlobalSet {
          linkMenus {
            ... on Craft_linkMenus_link_BlockType {
              uid
              headLink {
                url
                text
              }
              linkGroups {
                ... on Craft_linkGroups_BlockType {
                  uid
                  group: sublinkGroup {
                    ... on Craft_sublinkGroup_sublink_BlockType {
                      uid
                      type: typeHandle
                      sublink: linkObject {
                        text
                        url
                      }
                    }
                    ... on Craft_sublinkGroup_heading_BlockType {
                      uid
                      type: typeHandle
                      heading
                    }
                  }
                }
              }
            }
          }
          copyright: descriptor0
          tax: descriptor1
          address
          button: link0 {
            url
            text
          }
          privacyLink: link1 {
            url
            text
          }
          social {
            ... on Craft_social_account_BlockType {
              uid
              type: typeHandle
              platform
              accounturl
            }
            ... on Craft_social_accounts_BlockType {
              uid
              type: typeHandle
              platform
              accounts {
                label
                accountUrl
              }
            }
          }
        }
      }
      es: globalSet(handle: "footer", site: "artsCouncilSccSpanishEs") {
        ... on Craft_footer_GlobalSet {
          linkMenus {
            ... on Craft_linkMenus_link_BlockType {
              uid
              headLink {
                url
                text
              }
              linkGroups {
                ... on Craft_linkGroups_BlockType {
                  uid
                  group: sublinkGroup {
                    ... on Craft_sublinkGroup_sublink_BlockType {
                      uid
                      type: typeHandle
                      sublink: linkObject {
                        text
                        url
                      }
                    }
                    ... on Craft_sublinkGroup_heading_BlockType {
                      uid
                      type: typeHandle
                      heading
                    }
                  }
                }
              }
            }
          }
          copyright: descriptor0
          tax: descriptor1
          address
          button: link0 {
            url
            text
          }
          privacyLink: link1 {
            url
            text
          }
          social {
            ... on Craft_social_account_BlockType {
              uid
              type: typeHandle
              platform
              accounturl
            }
            ... on Craft_social_accounts_BlockType {
              uid
              type: typeHandle
              platform
              accounts {
                label
                accountUrl
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = globalSet => {
  const { linkMenus, copyright, tax, address, privacyLink, social, button } =
    globalSet;

  const nav = linkMenus?.map(links => {
    return {
      ...links?.headLink,
      uid: links?.uid,
      children: links?.linkGroups?.map(child => {
        return { ...child };
      }),
    };
  });

  nav.push({ ...button, button: true });

  return {
    nav,
    copyright,
    tax,
    address,
    privacyLink,
    social,
  };
};

const useData = () => {
  const { craft } = useStaticQuery(query);
  const { en, es } = craft;
  return {
    en: dataResolver(en),
    es: dataResolver(es),
  };
};

export default useData;
