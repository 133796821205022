import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query OpenStudiosFooterQuery {
    craft {
      en: globalSet(handle: "footer", site: "openStudios") {
        ... on Craft_footer_GlobalSet {
          address
        }
      }
      es: globalSet(handle: "footer", site: "openStudiosSpanishEs") {
        ... on Craft_footer_GlobalSet {
          address
        }
      }
    }
  }
`;

const dataResolver = globalSet => {
  const { address } = globalSet;

  return {
    address,
  };
};

const useData = () => {
  const { craft } = useStaticQuery(query);
  const { en, es } = craft;
  return {
    en: dataResolver(en),
    es: dataResolver(es),
  };
};

export default useData;
